import { useEffect, useState } from "react";

import Footer from "../../components/footer";
import Header from "../../components/header";
import NameSection from "../../components/nameSection";
import ButtonTwo from "../../components/buttonTwo";
import RememberOldEditions from "../../components/rememberOldEditions";
import FormData from "../../components/form";
import CardRegistration from "../../components/cardRegistration";
import CustomVideoPlayer from "../../components/video";
import ScrollingBackground from "../../components/line";
import NextEvent from "../../components/nextEvent";
import EventBanner from "../../components/eventBanner";
import DescriptionEvent from "../../components/descriptionEvent";

import {
  Container,
  ContainerAboutUs,
  ContainerButton,
  ContainerCards,
  ContainerEditions,
  ContainerFamilySoccerCamp,
  ContainerRegisteredButton,
  ContainerRegistrations,
  ContainerTable,
  ContentAboutUs,
  ContentEditions,
  ContentInstructions,
  ContentRegistrations,
  ContentTable,
  ContentTitle,
  DescriptionRegistrations,
  IconArrow,
  IconRegistrations,
  Instruction,
  Line,
  Table,
  TableTwo,
  TextAboutUs,
  TextDiscount,
  TextSoccerCamp,
} from "./styles";

import RegistrationsIcon from "../../assets/icons_registrations.svg";

import Iconbutton from "../../assets/icons/icon_button.svg";
import IconButtonTwo from "../../assets/icons/icon_button2.svg";
import IconButtonTwoWhite from "../../assets/icons/icon_button2_white.svg";

import InstructionOne from "../../assets/comandatuba/insctructionOne.svg";
import InstructionTwo from "../../assets/comandatuba/instructionTwo.svg";

import ImageArrow from "../../assets/icons/arrow.svg";

import ImageTable from "../../assets/comandatuba/table.svg";
import ImageTableMobile from "../../assets/comandatuba/table_mobile.svg";

const Home = () => {
  const [openModal, setOpenModal] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const handleClose = () => setOpenModal(false);

  useEffect(() => {
    if (isVisible) {
      document.querySelector("#event").scrollIntoView({ behavior: "smooth" });
    }
  }, [isVisible]);

  return (
    <Container>
      <FormData openModal={openModal} handleClose={handleClose} />
      <Header />

      <ContainerFamilySoccerCamp>
        <TextSoccerCamp>
          O <span style={{ color: "#F3B000" }}>Maior</span> Family Soccer Camp
          do mundo.
        </TextSoccerCamp>
        <IconArrow src={ImageArrow} alt="icone_seta" />
      </ContainerFamilySoccerCamp>

      <NextEvent />

      <ContainerAboutUs id="aboutus">
        <ContentAboutUs>
          <CustomVideoPlayer />
          <TextAboutUs>
            <NameSection
              textOne="SOBRE"
              textTwo="NÓS"
              style={{ justifyContent: "end" }}
              className="NameSection"
            />
            <label>
              Bem-vindo ao{" "}
              <span style={{ color: "#F3B000" }}>Caioba Soccer Camp!</span>
            </label>

            <p>
              "Aqui, transformamos sonhos em realidade através do futebol. Nossa
              missão é unir e fortalecer famílias, promovendo valores como
              respeito, igualdade e espírito de equipe."
            </p>
            <cite>- Caio Ribeiro</cite>
          </TextAboutUs>
        </ContentAboutUs>
      </ContainerAboutUs>

      <ContainerEditions id="timeline">
        <ContentEditions>
          <label>Reviva momentos marcantes das nossas edições passadas.</label>
          <label>
            EXPLORE NOSSA HISTÓRIA COM UMA LINHA DO TEMPO INTERATIVA
          </label>
        </ContentEditions>
        <RememberOldEditions />
      </ContainerEditions>

      <ScrollingBackground />

      <EventBanner />

      <ContainerRegistrations id="registration">
        <ContentRegistrations>
          <ContentTitle>
            <IconRegistrations src={RegistrationsIcon} alt="icon_inscricao" />
            <span>INSCRIÇÕES DO CAIOBA</span>
          </ContentTitle>

          <ContainerCards>
            <CardRegistration amount="1 INSCRIÇÃO" amountRegistration={1} />
            <CardRegistration amount="2 INSCRIÇÕES" amountRegistration={2} />
            <CardRegistration amount="3 INSCRIÇÕES" amountRegistration={3} />
          </ContainerCards>

          <DescriptionRegistrations>
            <span>*</span>O desconto para segunda e terceira inscrições é válido
            <span> somente para irmãos</span>. <br /> <span>**</span>Descontos
            não acumulativos.
          </DescriptionRegistrations>

          <ContainerRegisteredButton>
            <div>
              <ButtonTwo
                icon={true}
                iconImage={IconButtonTwo}
                backgroundColor="white"
                width="100%"
                lineHeight="24px"
                onClick={() => setIsVisible(true)}
                color="#F3B000"
                className="buttonOne"
              >
                VEJA OS VALORES DE HOSPEDAGEM
              </ButtonTwo>
            </div>
            <div>
              <ButtonTwo
                iconImage={Iconbutton}
                icon={true}
                width="100%"
                onClick={() => setOpenModal(true)}
                className="buttonTwo"
              >
                INSCREVA-SE AGORA
              </ButtonTwo>
            </div>
          </ContainerRegisteredButton>

          <DescriptionRegistrations>
            <span>*</span>Para participar, é necessário fazer as{" "}
            <span>reservas de hotel</span>, e inscrição do Caioba.
          </DescriptionRegistrations>

          <ContainerButton></ContainerButton>
        </ContentRegistrations>
      </ContainerRegistrations>

      {isVisible && (
        <>
          <DescriptionEvent />

          <ContainerTable>
            <ContentTable>
              <Table src={ImageTable} alt="tabela" loading="lazy" />
              <TableTwo src={ImageTableMobile} alt="tabela" loading="lazy" />
            </ContentTable>
            <TextDiscount>
              Valores considerados para tarifa comissionada em 10% para a
              agência. Taxas de ISS (5%) e taxa de serviço (10%) não inclusos
              nos valores acima apresentados.
            </TextDiscount>
            <Line />

            <ContentInstructions>
              <Instruction alt="instrução1" src={InstructionOne} />
              <Instruction alt="instrução2" src={InstructionTwo} />
            </ContentInstructions>
            <Line />

            <span>
              Após a inscrição, aguarde o e-mail da agência para prosseguir.
            </span>
            <ContainerButton>
              <ButtonTwo
                icon={true}
                iconImage={IconButtonTwoWhite}
                backgroundColor="#222221"
                onClick={() => setIsVisible(false)}
                width="100%"
              >
                FECHAR TABELA
              </ButtonTwo>
            </ContainerButton>
          </ContainerTable>
        </>
      )}

      <Footer />
    </Container>
  );
};

export default Home;
